/** @format */

import React, { useLayoutEffect, useState } from "react";
import { Card } from "primereact/card";
import { GetCourseById } from "../../../services/Api/CourseApi";
import { useNavigate, useParams } from "react-router";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Menu, MenuItem, IconButton } from "@mui/material";
import { BASE_URL_IMAGE } from "../../../services/Host";

const Layout1 = () => {
	const { id } = useParams();
	const navigate = useNavigate();
	const [courseData, setCourseData] = useState(null);
	const [imagePreview, setImagePreview] = useState(null);
	const [courseMenuAnchorEl, setCourseMenuAnchorEl] = useState(null);

	const fetchCourseData = async () => {
		try {
			const res = await GetCourseById(id);
			setCourseData(res.data.data);
			if (
				res.data.data.course_attachments &&
				res.data.data.course_attachments.length > 0
			) {
				const attachment = res.data.data.course_attachments[0];
				setImagePreview(`${BASE_URL_IMAGE}${attachment.file_name}`);
			}
		} catch (err) {
			console.log(err, "error");
		}
	};

	useLayoutEffect(() => {
		fetchCourseData();
	}, [id]);

	const truncateDescription = (description, wordLimit) => {
		if (!description) return "";
		const words = description.split(" ");
		if (words.length <= wordLimit) return description;
		return words.slice(0, wordLimit).join(" ") + "...";
	};

	const handleCourseMenuClick = (event) => {
		setCourseMenuAnchorEl(event.currentTarget);
	};

	const handleCourseMenuClose = () => {
		setCourseMenuAnchorEl(null);
	};

	const handleEditCourse = () => {
		navigate(`/editcourse/${id}`);
		handleCourseMenuClose();
	};

	const handleViewCourse = () => {
		navigate(`/viewcourse/${id}`);
		handleCourseMenuClose();
	};

	return (
		<div style={{ marginBottom: "20px" }}>
			<Card>
				<div style={{ display: "flex", flexDirection: "row" }}>
					<div>
						{imagePreview && (
							<img
								crossOrigin="anonymous"
								src={imagePreview}
								alt="Preview"
								style={{
									height: "80px",
									width: "80px",
									marginRight: "10px",
									borderRadius: "10px",
								}}
							/>
						)}
					</div>
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "space-between",
							width: "100%",
						}}
					>
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								width: "95%",
							}}
						>
							<h5>{courseData?.name}</h5>
							<div
								dangerouslySetInnerHTML={{
									__html: truncateDescription(courseData?.description, 40),
								}}
							/>
						</div>
						<div>
							<IconButton
								aria-controls="course-menu"
								aria-haspopup="true"
								onClick={handleCourseMenuClick}
							>
								<MoreVertIcon />
							</IconButton>
							<Menu
								id="course-menu"
								anchorEl={courseMenuAnchorEl}
								open={Boolean(courseMenuAnchorEl)}
								onClose={handleCourseMenuClose}
							>
								<MenuItem onClick={handleEditCourse}>Edit Course</MenuItem>
								<MenuItem onClick={handleViewCourse}>View Course</MenuItem>
							</Menu>
						</div>
					</div>
				</div>
			</Card>
		</div>
	);
};

export default Layout1;
