import React, { useLayoutEffect, useState } from "react";
import { Box } from "@mui/material";
import { Button } from "primereact/button";
import { useNavigate, useParams } from "react-router";
import { GetQuestionsByTestId, CreateQuestion } from "../../services/Api/CourseApi";
import { Row, Col } from "antd";
import { Form } from "react-bootstrap";
import { CheckCircleOutlined } from "@ant-design/icons";
import { Drawer, message } from "antd";

const ViewTest = () => {
	const { id } = useParams();
	const navigate = useNavigate();
	const [courseData, setCourseData] = useState(null);
	const [questions, setQuestions] = useState([]);
	const [question, setQuestion] = useState("");
	const [disable, setDisable] = useState(false);
	const [showDrawer, setShowDrawer] = useState(false);

	const getCourseData = () => {
		GetQuestionsByTestId(id)
			.then((res) => {
				setCourseData(res.data.data);
				setQuestions(res.data.data.test_questions);
			})
			.catch((err) => {
				console.log(err, "error");
			});
	};

	useLayoutEffect(() => {
		getCourseData();
	}, [id]);



	return (
		<Box>
			<Box display="flex" justifyContent="space-between" alignItems="center" marginBottom="20px">
				<div>
					<h3 className="page-title">{courseData?.name}</h3>
				</div>
				<div>
					<Button
						label="Return to Test"
						icon="pi pi-arrow-left"
						severity="secondary"
						onClick={() => navigate(`/test`)}
						style={{ borderRadius: "5px", height: "47px", marginRight: "10px" }}
					/>

				
				</div>
			</Box>

			<div className="test-knowledge">
				{questions.length > 0 ? (
					<Form className="test-form">
						{questions.map((q, qIndex) => (
							<Form.Group as={Row} className="mb-3" key={q.id}>
								<Form.Label as="legend" column sm={12}>
									{qIndex + 1}. {q.question}
								</Form.Label>
								<Col sm={24}>
									{q.test_options.length > 0 ? (
										<ul style={{ listStyleType: "none", paddingLeft: 0 }}>
											{q.test_options.map((answer, aIndex) => (
												<li
													key={answer.id}
													style={{
														display: "flex",
														alignItems: "center",
														marginBottom: "8px",
													}}
												>
													{aIndex + 1}. {answer.option}
													{answer.id === q.right_answer_id && (
														<CheckCircleOutlined
															style={{
																color: "green",
																marginLeft: "10px",
																fontSize: "16px",
															}}
														/>
													)}
												</li>
											))}
										</ul>
									) : (
										<p style={{ color: "red" }}>No options added yet</p>
									)}
								</Col>
							</Form.Group>
						))}
					</Form>
				) : (
					<p style={{ color: "red" }}>No questions added yet</p>
				)}
			</div>

	
		</Box>
	);
};

export default ViewTest;


