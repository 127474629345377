/** @format */

import React, { useLayoutEffect, useState } from "react";
import { Box } from "@mui/material";
import { Button } from "primereact/button";
import { useNavigate, useParams } from "react-router";
import {
	GetQuestionsByTestId,
	ManageTestQuestions,
	UpdateTestQuestion,
	DeleteTestQuestion,
} from "../../services/Api/CourseApi";
import { Row, Col } from "antd";
import { Form } from "react-bootstrap";
import { Drawer, Radio, message, Dropdown, Menu, Popconfirm } from "antd";
import { CheckCircleFilled } from "@ant-design/icons";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteAlert from "./DeleteAlert";

const AddQuestions = () => {
	const { id } = useParams();
	const navigate = useNavigate();
	const [courseData, setCourseData] = useState(null);
	const [questions, setQuestions] = useState([]);
	const [question, setQuestion] = useState("");
	const [optionType, setOptionType] = useState(null);
	const [options, setOptions] = useState([]);
	const [disable, setDisable] = useState(false);
	const [showAddDrawer, setShowAddDrawer] = useState(false);
	const [showEditDrawer, setShowEditDrawer] = useState(false);
	const [selectedAnswer, setSelectedAnswer] = useState(null);
	const [editQuestionId, setEditQuestionId] = useState(null);

	const getCourseData = () => {
		GetQuestionsByTestId(id)
			.then((res) => {
				setCourseData(res.data.data);
				setQuestions(res.data.data.test_questions);
			})
			.catch((err) => {
				console.log(err, "error");
			});
	};

	useLayoutEffect(() => {
		getCourseData();
	}, [id]);

	const handleOptionTypeChange = (e) => {
		const selectedType = e.target.value;
		setOptionType(selectedType);
		setOptions(new Array(selectedType).fill("")); // Initialize options with empty strings
	};

	const handleOptionChange = (index, value) => {
		const updatedOptions = [...options];
		updatedOptions[index] = value;
		setOptions(updatedOptions);
	};

	const handleAnswerChange = (e) => {
		setSelectedAnswer(e.target.value);
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		setDisable(true);

		// Frontend Validations
		if (!question) {
			message.error("Please type Question");
			setDisable(false);
			return;
		}

		if (options.some((opt) => !opt)) {
			message.error("All options must be filled");
			setDisable(false);
			return;
		}

		if (!selectedAnswer) {
			message.error("Please select the correct answer");
			setDisable(false);
			return;
		}

		try {
			const formData = new FormData();
			formData.append("question", question);
			formData.append("test_id", id);

			// Append each option individually
			options.forEach((option) => {
				formData.append("options[]", option);
			});

			formData.append("right_answer", selectedAnswer);

			let response;

			if (editQuestionId) {
				// Call Update API if in edit mode
				response = await UpdateTestQuestion(editQuestionId, formData);
				if (response.status === 201) {
					message.success("Question updated successfully!");
				}
			} else {
				// Call Add API if not in edit mode
				response = await ManageTestQuestions(formData);
				if (response.status === 201) {
					message.success("Question added successfully!");
				}
			}

			// Reset state and refresh question list after successful submission
			setQuestion("");
			setOptions([]);
			setOptionType(null);
			setSelectedAnswer(null);
			getCourseData();
			setShowAddDrawer(false);
			setShowEditDrawer(false);
			setEditQuestionId(null);
		} catch (error) {
			if (error.response) {
				if (error.response.status === 401) {
					message.error("Token expired");
					localStorage.removeItem("adminToken");
					setTimeout(() => {
						navigate("/Login");
					}, 1000);
				} else if (error.response.status === 400) {
					if (
						error.response.data.message ===
						"This Question already exists for the quiz"
					) {
						message.error("This Question already exists for the quiz");
					} else if (
						error.response.data.message ===
						"Duplicate options are not allowed within the same question."
					) {
						message.error(
							"Duplicate options are not allowed within the same question."
						);
					} else {
						message.error("Something went wrong");
					}
				} else {
					message.error("Something went wrong");
				}
			} else {
				message.error("Network error");
			}
			setDisable(false);
		}
	};

	const handleDrawerClose = () => {
		setShowAddDrawer(false);
		setShowEditDrawer(false);
		setQuestion("");
		setOptionType(null);
		setOptions([]);
		setSelectedAnswer(null); // Reset selected answer
		setEditQuestionId(null); // Reset edit question ID
	};

	const menu = (questionId, questionData) => (
		<Menu>
			<Menu.Item onClick={() => editQuestion(questionData)}>
				Edit Question
			</Menu.Item>
			<Menu.Item>
				<DeleteAlert
					title="Question"
					handleDelete={() => handleDelete(questionId)}
				/>
				{/* <Popconfirm
					title="Are you sure you want to delete this question?"
					onConfirm={() => handleDelete(questionId)}
					okText="Yes"
					cancelText="No"
					center
				>
					Delete Question
				</Popconfirm> */}
			</Menu.Item>
		</Menu>
	);

	const handleDelete = async (questionId) => {
		try {
			await DeleteTestQuestion(questionId);
			message.success("Question deleted successfully");
			getCourseData();
		} catch (error) {
			message.error("Error deleting question");
		}
	};

	const editQuestion = (questionData) => {
		setQuestion(questionData.question);
		setOptionType(questionData.test_options.length); // Assuming 2 or 4 options
		setOptions(questionData.test_options.map((opt) => opt.option));
		setSelectedAnswer(
			questionData.test_options.find(
				(opt) => opt.id === questionData.right_answer_id
			)?.option || null
		);
		setEditQuestionId(questionData.id);
		setShowEditDrawer(true); // Open edit drawer
	};

	return (
		<Box>
			<Box
				display="flex"
				justifyContent="space-between"
				alignItems="center"
				marginBottom="20px"
			>
				<div>
					<h3 className="page-title">{courseData?.name}</h3>
				</div>
				<div>
					<Button
						label="Return to Test"
						icon="pi pi-arrow-left"
						severity="secondary"
						onClick={() => navigate(`/editTest/${courseData.id}`)} // Accessing courseData.id here
						style={{ borderRadius: "5px", height: "47px", marginRight: "10px" }}
					/>

					<Button
						label="Add New Question"
						icon="pi pi-plus"
						style={{ borderRadius: "5px", height: "47px" }}
						onClick={() => setShowAddDrawer(true)} // Open add drawer
					/>
				</div>
			</Box>

			<div className="test-knowledge">
				{questions.length > 0 ? (
					<Form className="test-form">
						{questions.map((q, qIndex) => (
							<Form.Group
								as={Row}
								className="mb-3"
								key={q.id}
								style={{ width: "90%" }}
							>
								<div
									style={{
										display: "flex",
										flexDirection: "row",
										justifyContent: "space-between",
										width: "100%",
									}}
								>
									<Form.Label as="legend" column sm={12}>
										{qIndex + 1}. {q.question}
									</Form.Label>
									<Dropdown
										overlay={menu(q.id, q)}
										trigger={["click"]}
										placement="bottomRight"
										arrow={{
											pointAtCenter: true,
										}}
									>
										<MoreVertIcon
											style={{
												marginLeft: "10px",
												cursor: "pointer",
												width: "10%",
											}}
										/>
									</Dropdown>
								</div>
								<Col sm={24}>
									{q.test_options.length > 0 ? (
										<ul style={{ listStyleType: "none", paddingLeft: 0 }}>
											{q.test_options.map((answer, aIndex) => (
												<li
													key={answer.id}
													style={{
														display: "flex",
														alignItems: "center",
														marginBottom: "8px",
													}}
												>
													{aIndex + 1}. {answer.option}
													{answer.id === q.right_answer_id && (
														<CheckCircleFilled
															twoToneColor="#52c41a"
															style={{
																color: "darkgreen",
																marginLeft: "10px",
																fontSize: "18px",
															}}
														/>
													)}
												</li>
											))}
										</ul>
									) : (
										<p style={{ color: "red" }}>No options added yet</p>
									)}
								</Col>
							</Form.Group>
							//
						))}
					</Form>
				) : (
					<p>No questions have been added yet.</p>
				)}
			</div>

			<Drawer
				title="Add New Question"
				placement="right"
				closable={true}
				onClose={handleDrawerClose}
				open={showAddDrawer}
				width={400}
			>
				<Form>
					<Form.Group className="mb-3 w-100">
						<Form.Label>Question:</Form.Label>
						<Form.Control
							type="text"
							required
							placeholder="Please enter Question here"
							value={question}
							onChange={(e) => setQuestion(e.target.value)}
							className="new_form_control"
							id="question-input"
						/>
					</Form.Group>

					<Form.Group className="mb-3 w-100">
						<Form.Label>Choose Option Type:</Form.Label>
						<Radio.Group onChange={handleOptionTypeChange} value={optionType}>
							<Radio value={2}>2-Option Type</Radio>
							<Radio value={4}>4-Option Type</Radio>
						</Radio.Group>
					</Form.Group>

					{optionType &&
						options.map((option, index) => (
							<Form.Group key={index} className="mb-3">
								<Form.Label>Option {index + 1}:</Form.Label>
								<div
									style={{
										display: "flex",
										flexDirection: "row",
										justifyContent: "space-between",
										width: "100%",
										gap: "10px",
									}}
								>
									<Form.Control
										type="text"
										placeholder={`Enter option ${index + 1}`}
										value={option}
										onChange={(e) => handleOptionChange(index, e.target.value)}
										className="new_form_control"
									/>
									<Radio
										name="answer" // Ensure all radio buttons in this group have the same name
										value={option}
										checked={selectedAnswer === option}
										onChange={handleAnswerChange}
										style={{
											padding: ".375rem .75rem",
											border: "1px solid #ced4da",
											borderRadius: "5px",
										}}
									/>
								</div>
							</Form.Group>
						))}
				</Form>
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						gap: "10px",
						width: "100%",
						marginTop: "40px",
					}}
				>
					<Button
						label="Cancel"
						severity="secondary"
						onClick={handleDrawerClose}
						style={{
							fontSize: "17px",
							width: "50%",
							padding: "8px 20px",
						}}
					/>
					<Button
						label="Save"
						// loading={disable}
						type="submit"
						onClick={handleSubmit}
						style={{
							fontSize: "17px",
							width: "50%",
							padding: "5px 20px",
						}}
					/>
				</div>
			</Drawer>

			<Drawer
				title="Edit Question"
				placement="right"
				closable={true}
				onClose={handleDrawerClose}
				open={showEditDrawer}
				width={500}
			>
				<Form>
					<Form.Group controlId="question" className="mb-3 w-100">
						<Form.Label>Question</Form.Label>
						<Form.Control
							as="textarea"
							rows={3}
							value={question}
							onChange={(e) => setQuestion(e.target.value)}
						/>
					</Form.Group>

					{options.map((option, index) => (
						<Form.Group
							controlId={`option${index}`}
							key={index}
							className="mb-3 w-100"
						>
							<Form.Label>Option {index + 1}</Form.Label>
							<div
								style={{
									display: "flex",
									flexDirection: "row",
									justifyContent: "space-between",
									width: "100%",
									gap: "10px",
								}}
							>
								<Form.Control
									type="text"
									value={option}
									onChange={(e) => handleOptionChange(index, e.target.value)}
									style={{ marginRight: "10px" }}
								/>
								<Radio
									value={option}
									checked={selectedAnswer === option}
									onChange={handleAnswerChange}
									style={{
										padding: ".375rem .75rem",
										border: "1px solid #ced4da",
										borderRadius: "5px",
									}}
								/>
							</div>
						</Form.Group>
					))}
				</Form>
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						gap: "10px",
						width: "100%",
						marginTop: "40px",
					}}
				>
					<Button
						label="Cancel"
						severity="secondary"
						onClick={handleDrawerClose}
						style={{
							fontSize: "17px",
							width: "50%",
							padding: "8px 20px",
						}}
					/>
					<Button
						type="submit"
						onClick={handleSubmit}
						// disabled={disable}
						label="Update"
						style={{
							fontSize: "17px",
							width: "50%",
							padding: "8px 20px",
						}}
					/>
				</div>
			</Drawer>
		</Box>
	);
};

export default AddQuestions;
