/** @format */

import axios from "axios";
import { BASE_URL } from "../Host";

//get all user
export const GetCorporate = async (adminToken, role_id) => {
	try {
		const res = await axios.get(BASE_URL + "corporate/getAllCorporateManager", {
			headers: {
				"x-access-token": `${adminToken}`,
				"Content-Type": "multipart/form-data",
			},
		});

		return res;
	} catch (error) {
		// Handle error if needed
		console.error("Error fetching users:", error);
		throw error;
	}
};

export const GetCorporateById = async (id) => {
	return await axios.get(BASE_URL + `corporate/getCorporateById/${id}`);
  };

//delete user
// export const DeleteCorporate = async (user_id, adminToken) => {
// 	const formData = new FormData();
// 	formData.append("user_id", user_id);
// 	return axios.post(BASE_URL + "corporate/deleteCorporate", {
// 		data: formData,
// 	});
// };

export const DeleteCorporate = async (user_id) => {
	return await axios.post(
		`${BASE_URL}/corporate/deleteCorporate`,
		{ user_id: user_id },
		{
			headers: {
				"x-access-token": `${localStorage.getItem("adminToken")}`,
				"Content-Type": "multipart/form-data",
			},
		}
	);
};


export const GetEmployeesByCorporateId = async (id) => {
	try {
		const res = await axios.get(
			BASE_URL + `corporate/getEmployeeByCorporateId/${id}`
		);
		return res;
	} catch (error) {
		console.error("Error fetching users:", error);
		throw error;
	}
};

export const GetListOfSharedDataByCorporateId = async (id) => {
	try {
		const res = await axios.get(
			BASE_URL + `admin/user/getListOfSharedDataByCorporateId/${id}`
		);
		return res;
	} catch (error) {
		console.error("Error fetching users:", error);
		throw error;
	}
};

export const NewCorporate = async ({ email, name, company_name,mobile }) => {
	const formData = new FormData();
  
	formData.append("email", email);
	formData.append("name", name);
	formData.append("company_name", company_name);
	formData.append("mobile", mobile);
  
	return await axios.post(BASE_URL + "corporate/addCorporateManager", formData, {
	  headers: {
		"x-access-token": `${localStorage.getItem("adminToken")}`,
		"Content-Type": "multipart/form-data",
	  },
	});
  };
