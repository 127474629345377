/** @format */

import React, { useEffect, useState } from "react";
import { Table, Space, message } from "antd";
import { Button } from "primereact/button";
import { useNavigate } from "react-router";
import { InputText } from "primereact/inputtext";
import { Box } from "@material-ui/core";
import { GetCourse, DeleteCourse } from "../../../services/Api/CourseApi";
import Alert from "../../Customer/Alert";
import { Tooltip } from "antd";
import { IoArrowForwardCircleOutline } from "react-icons/io5";

const Course = () => {
	const navigate = useNavigate();
	const [data, setData] = useState([]);
	const [userBackupData, setUserBackupData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [tableParams, setTableParams] = useState({
		pagination: {
			current: 1,
			pageSize: 20,
		},
		sortField: null,
		sortOrder: null,
	});

	const columns = [
		{
			title: "S.No.",
			dataIndex: "index",
			sorter: (a, b) => a.index - b.index,
		},
		{
			title: "Name",
			dataIndex: "name",
			sorter: (a, b) => a.user_profile.name.localeCompare(b.user_profile.name),
			width: "20%",
		},
		{
			title: "Price",
			dataIndex: "price",
			width: "20%",
			render: (text) => `$${text}`, 
		},
		{
			title: "Average Rating",
			dataIndex: "average_rating",
			width: "20%",
			render: (_, record) =>
				record.average_rating > 0 ? (
					<>
						{record.average_rating}{" "}
						<IoArrowForwardCircleOutline
							style={{
								fontSize: "20px",
								cursor: "pointer",
								marginLeft: "8px",
								marginTop: "-1px",
							}}
							className="redirect_button"
							onClick={() => navigate(`/courseRatings/${record.id}`)}
						/>
						{/* <Button
					type="link"
					onClick={() => navigate(`/courseRatings/${record.id}`)}
				  >
					View
				  </Button> */}
					</>
				) : (
					"0"
				),
		},

		{
			title: "Number Of Lecture",
			dataIndex: "lecture_count",
			width: "20%",
		},
		{
			title: "Action",
			dataIndex: "action",
			render: (_, record) => (
				<Space size="middle">
					<Tooltip placement="bottom" title="View Course">
						<Button
							icon="pi pi-eye"
							rounded
							outlined
							severity="warning"
							className="mr-2"
							style={{ margin: "0px", borderRadius: "25px" }}
							onClick={(event) => navigateToViewCourse(event, record.id)}
						></Button>
					</Tooltip>
					<Tooltip placement="bottom" title="Edit Course">
						<Button
							icon="pi pi-pencil"
							rounded
							outlined
							style={{ borderRadius: "25px" }}
							onClick={(event) => navigateToEditCourse(event, record.id)}
						/>
					</Tooltip>

					<Alert title="Course" handleDelete={() => removeUser(record.id)} />
				</Space>
			),
		},
	];

	const getData = async (params = {}) => {
		try {
			setLoading(true);
			let result = await GetCourse(localStorage.getItem("adminToken"), params);
			const newData = result.data.data.map((item, index) => ({
				...item,
				index: index + 1,
			}));
			setData(newData);
			setUserBackupData(newData);
		} catch (e) {
			console.log(e);
			if (e.response && e.response.status === 401) {
				navigate("/error401");
				console.log("You do not have access to this page as a sub-admin.");
			} else {
				console.log("Error loading data. Please try again later.");
			}
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		getData({
			page: tableParams.pagination.current,
			pageSize: tableParams.pagination.pageSize,
			sortField: tableParams.sortField,
			sortOrder: tableParams.sortOrder,
		});
	}, [tableParams]);

	const handleTableChange = (pagination, filters, sorter) => {
		setTableParams({
			pagination,
			filters,
			sortField: sorter.field,
			sortOrder: sorter.order,
		});
	};

	const onSearch = (searchField) => {
		const LIST = [...userBackupData];
		const searchList = [];

		for (let i in LIST) {
			if (LIST[i]?.name?.toLowerCase()?.includes(searchField?.toLowerCase())) {
				searchList.push(LIST[i]);
			}
		}

		setData(searchList);
	};

	const removeUser = async (id) => {
		setIsModalVisible(false);
		try {
			let res = await DeleteCourse(id, localStorage.getItem("adminToken"));
			message.success(res?.data?.message);
			getData();
		} catch (error) {
			console.log(error, "error");
			if (error.response && error.response.status === 401) {
				message.error("Token expired");
				localStorage.removeItem("adminToken");
				setTimeout(() => {
					navigate("/Login");
				}, 3000);
			} else {
				message.error("Something went wrong");
			}
		}
	};

	const exportToCSV = () => {
		const csvContent =
			"data:text/csv;charset=utf-8," +
			"ID,Name,Price,Rating,Number Of lectures\n" +
			data
				.map(
					(row) =>
						`${row.id},${row.name},${row.price},${row.average_rating},${row.lecture_count}`
				)
				.join("\n");

		const encodedURI = encodeURI(csvContent);
		const link = document.createElement("a");
		link.setAttribute("href", encodedURI);
		link.setAttribute("download", "course.csv");
		document.body.appendChild(link);
		link.click();
	};

	const navigateToAddCourse = () => {
		navigate("/addCourse");
	};

	const navigateToViewCourse = (event, id) => {
		navigate(`/viewCourse/${id}`);
	};

	const navigateToEditCourse = (event, id) => {
		navigate(`/editCourse/${id}`);
	};

	return (
		<Box>
			<Box
				display="flex"
				justifyContent="space-between"
				alignItems="center"
				marginBottom="20px"
			>
				<div>
					<h3 className="page-title">COURSE MANAGEMENT</h3>
					<p className="page-sub-title">View, delete, and add Course</p>
				</div>
				<Box display="flex" justifyContent="space-between" alignItems="center">
					<Box>
						<span className="p-input-icon-left">
							<i className="pi pi-search" />
							<InputText
								type="search"
								onChange={(e) => {
									onSearch(e.target.value);
								}}
								placeholder="Search..."
							/>
						</span>

						<Button
							icon="pi pi-cloud-download"
							severity="success"
							style={{
								marginLeft: "10px",
								borderRadius: "5px",
								height: "47px",
							}}
							onClick={exportToCSV}
						/>
						<Button
							icon="pi pi-plus"
							severity="info"
							style={{
								margin: "0px 10px",
								borderRadius: "5px",
								height: "47px",
							}}
							onClick={navigateToAddCourse}
						/>
					</Box>
				</Box>
			</Box>
			<Table
				columns={columns}
				rowKey={(record) => record.id}
				dataSource={data}
				pagination={tableParams.pagination}
				loading={loading}
				onChange={handleTableChange}
			/>
		</Box>
	);
};

export default Course;
