import React from 'react';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { Button, Modal, Space } from 'antd';
const { confirm } = Modal;
const DeleteAlert = ({handleDelete,title}) => {
  const showDeleteConfirm = () => {
    confirm({
      title: `Are you sure you want to delete this ${title}?`,
      icon: <ExclamationCircleFilled />,
      content: '',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        handleDelete(); 
      },
      onCancel() {
      },
    });
  };
  return (
    <Space wrap>
      <div onClick={showDeleteConfirm} type="dashed">
        Delete Question
      </div>
    </Space>
  );
};
export default DeleteAlert;