/** @format */

import axios from "axios";
import { BASE_URL } from "../Host";

export const GetCourse = async (adminToken, role_id) => {
	try {
		const res = await axios.get(BASE_URL + "course/getCourse", {
			headers: {
				"x-access-token": `${adminToken}`,
				"Content-Type": "multipart/form-data",
			},
		});

		return res;
	} catch (error) {
		// Handle error if needed
		console.error("Error fetching users:", error);
		throw error;
	}
};

export const GetCourseById = async (id) => {
	return await axios.get(BASE_URL + `course/findCourseById/${id}`);
};

export const EditCourse = async (id, formData) => {
	return await axios.put(BASE_URL + `course/updateCourse/${id}`, formData, {
		headers: {
			"x-access-token": `${localStorage.getItem("adminToken")}`,
			"Content-Type": "multipart/form-data",
		},
	});
};

export const CreateCourse = async (formData) => {
	return await axios.post(BASE_URL + "course/createCourse", formData, {
		headers: {
			"x-access-token": `${localStorage.getItem("adminToken")}`,
			"Content-Type": "multipart/form-data",
		},
	});
};

export const DeleteCourse = async (id) => {
	return axios.delete(BASE_URL + `course/deleteCourse/${id}`);
};

export const CreateLecture = async (formData) => {
	return await axios.post(BASE_URL + "course/createLecture", formData, {
		headers: {
			"x-access-token": `${localStorage.getItem("adminToken")}`,
			"Content-Type": "multipart/form-data",
		},
	});
};

export const UpdateLecture = async (id, formData) => {
	return await axios.put(BASE_URL + `course/updateLecture/${id}`, formData, {
		headers: {
			"x-access-token": `${localStorage.getItem("adminToken")}`,
			"Content-Type": "multipart/form-data",
		},
	});
};

export const DeleteLecture = async (id) => {
	return axios.delete(BASE_URL + `course/deleteLecture/${id}`);
};

export const GetQuestionsByLectureId = async (id) => {
	return await axios.get(BASE_URL + `quiz/getQuestionsByLectureId/${id}`);
};

export const ManageLectureQuestions = async (formData) => {
	return await axios.post(BASE_URL + "quiz/manageQuiz", formData, {
		headers: {
			"x-access-token": `${localStorage.getItem("adminToken")}`,
			"Content-Type": "multipart/form-data",
		},
	});
};

export const UpdateLectureQuestion = async (id, formData) => {
	return await axios.put(BASE_URL + `quiz/updateQuizQuestion/${id}`, formData, {
		headers: {
			"x-access-token": `${localStorage.getItem("adminToken")}`,
			"Content-Type": "multipart/form-data",
		},
	});
};

export const DeleteLectureQuestion = async (id) => {
	return axios.delete(BASE_URL + `quiz/deleteQuizQuestion/${id}`);
};


// test apis
export const GetAllTest = async (adminToken, role_id) => {
	try {
		const res = await axios.get(BASE_URL + "test/getAllTest", {
			headers: {
				"x-access-token": `${adminToken}`,
				"Content-Type": "multipart/form-data",
			},
		});

		return res;
	} catch (error) {
		// Handle error if needed
		console.error("Error fetching users:", error);
		throw error;
	}
};

export const GetTestById = async (id) => {
	return await axios.get(BASE_URL + `test/findTestById/${id}`);
};

export const CreateTest = async (formData) => {
	return await axios.post(BASE_URL + "test/createTest", formData, {
		headers: {
			"x-access-token": `${localStorage.getItem("adminToken")}`,
			"Content-Type": "multipart/form-data",
		},
	});
};

export const UpdateTest = async (id, formData) => {
	return await axios.put(BASE_URL + `test/updateTest/${id}`, formData, {
		headers: {
			"x-access-token": `${localStorage.getItem("adminToken")}`,
			"Content-Type": "multipart/form-data",
		},
	});
};

export const DeleteTest = async (id) => {
	return axios.delete(BASE_URL + `test/deleteTest//${id}`);
};

export const GetAllCourseName = async (adminToken, role_id) => {
	try {
		const res = await axios.get(BASE_URL + "course/getAllCourseName", {
			headers: {
				"x-access-token": `${adminToken}`,
				"Content-Type": "multipart/form-data",
			},
		});

		return res;
	} catch (error) {
		// Handle error if needed
		console.error("Error fetching users:", error);
		throw error;
	}
};

export const GetQuestionsByTestId = async (id) => {
	return await axios.get(BASE_URL + `test/getQuestionsByTestId/${id}`);
};

export const CreateQuestion = async (formData) => {
	return await axios.post(BASE_URL + "test/createQuestion", formData, {
		headers: {
			"x-access-token": `${localStorage.getItem("adminToken")}`,
			"Content-Type": "multipart/form-data",
		},
	});
};

export const ManageTestQuestions = async (formData) => {
	return await axios.post(BASE_URL + "test/manageTestQuestions", formData, {
		headers: {
			"x-access-token": `${localStorage.getItem("adminToken")}`,
			"Content-Type": "multipart/form-data",
		},
	});
};

export const UpdateTestQuestion = async (id, formData) => {
	return await axios.put(BASE_URL + `test/updateTestQuestion/${id}`, formData, {
		headers: {
			"x-access-token": `${localStorage.getItem("adminToken")}`,
			"Content-Type": "multipart/form-data",
		},
	});
};

export const DeleteTestQuestion = async (id) => {
	return axios.delete(BASE_URL + `test/deleteTestQuestion/${id}`);
};

export const CreateOptions = async (formData) => {
	return await axios.post(BASE_URL + "test/createAnswers", formData, {
		headers: {
			"x-access-token": `${localStorage.getItem("adminToken")}`,
			"Content-Type": "multipart/form-data",
		},
	});
};

export const CreateCorrectOption = async (formData) => {
	return await axios.post(BASE_URL + "test/provideRightAnswer", formData, {
		headers: {
			"x-access-token": `${localStorage.getItem("adminToken")}`,
			"Content-Type": "multipart/form-data",
		},
	});
};

//view course api

export const GetFindLecturesById = async (id) => {
	return await axios.get(`${BASE_URL}course/findLectureById/${id}`);
};

export const GetLecturesList = async (id, headers) => {
	return await axios.get(`${BASE_URL}course/getAllLecturesInCourse/${id}`, {
		headers,
	});
};



export const GetReviewsByCourseId = async (course_id) => {
    return await axios.post(`${BASE_URL}/rating/getAllReviewsAndRatings`, { course_id }, {
        headers: {
            "x-access-token": `${localStorage.getItem("adminToken")}`,
            "Content-Type": "multipart/form-data",
        },
    });
};

export const saveUpdatedLectures = async (id,updatedLectures) => {
	try {
		const response = await axios.put(
			`${BASE_URL}course/reorder/${id}`,
			{
				lectures: updatedLectures.map((lecture) => ({
					id: lecture.id,
					new_lecture_number: lecture.lecture_number,
				})),
			},
			{
				headers: {
					"x-access-token": localStorage.getItem("adminToken"),
					"Content-Type": "application/json",
				},
			}
		);

		return response.data; // Handle success response or return data
	} catch (error) {
		console.error('Error saving updated lectures:', error);
		throw error; // Propagate error for further handling
	}
};



//quiz apid


