/** @format */

import { Box } from "@mui/material";
import { Button } from "primereact/button";
import React, { useLayoutEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import {
	CreateQuestion,
	GetQuestionsByLectureId,
} from "../../services/Api/CourseApi";
import { message } from "antd";

const AddQuiz = () => {
	const { id } = useParams();
	const navigate = useNavigate();
	const [courseData, setCourseData] = useState(null);
	const [disable, setDisable] = useState(false);
	const [question, seQuestion] = useState("");

	const getCourseData = () => {
		GetQuestionsByLectureId(id)
			.then((res) => {
				setCourseData(res.data.data);
				console.log("GetCourseById=>", res.data.data);
			})
			.catch((err) => {
				console.log(err, "error");
			});
	};

	useLayoutEffect(() => {
		getCourseData();
	}, [id]);

	const handleSubmit = async (event) => {
		event.preventDefault();
		setDisable(true);

		if (!question) {
			message.error("Please type Question");
			setDisable(false);
			return;
		}

		try {
			const formData = new FormData();
			formData.append("question", question);
			formData.append("lecture_id", id);
			formData.append("course_id", courseData.course_id);

			const response = await CreateQuestion(formData);

			if (response.status === 201) {
				message.success("Question added successfully!");
			}

			setDisable(false);
		} catch (error) {
			if (error.response && error.response.status === 401) {
				message.error("Token expired");
				localStorage.removeItem("adminToken");
				setTimeout(() => {
					navigate("/Login");
				}, 1000);
			} else {
				message.error("Something went wrong");
			}
			setDisable(false);
		}
	};

	return (
		<Box>
			<Box
				display="flex"
				justifyContent="space-between"
				alignItems="center"
				marginBottom="20px"
			>
				<div>
					<h3 className="page-title">COURSE MANAGEMENT</h3>
					<p className="page-sub-title">Create New Section</p>
				</div>
				<div>
					<Button
						icon="pi pi-arrow-left"
						severity="secondary"
						onClick={() => navigate(`/addLecture/${courseData.course_id}`)}
						style={{ borderRadius: "5px", height: "47px" }}
					>
						<span style={{ marginLeft: "5px" }}>Return to Course</span>
					</Button>
				</div>
			</Box>

			<div
				style={{
					display: "flex",
					justifyContent: "center",
					marginTop: "170px",
					gap: "20px",
				}}
			>
				<Button
					icon="pi pi-arrow-right"
					label="View All Questions"
					iconPos="right"
					onClick={() => navigate(`/viewQuestions/${id}`)}
					style={{ borderRadius: "5px", height: "47px" }}
				>
					
				</Button>
				<Button
					icon="pi pi-plus"
					severity="help"
					label="Add More Questions"
					onClick={() => navigate(`/addQuizQuestions/${id}`)}
					style={{ borderRadius: "5px", height: "47px" }}
				>
					
				</Button>
			</div>
		</Box>
	);
};

export default AddQuiz;
