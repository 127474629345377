/** @format */

import React, { useEffect, useState } from "react";
import { Space, Table } from "antd";
import { Button } from "primereact/button";
import { useNavigate } from "react-router";
import { InputText } from "primereact/inputtext";
import { Box, IconButton, Menu, MenuItem } from "@material-ui/core";
import { GetPayment } from "../../services/Api/Api";
import moment from "moment";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const Payment = () => {
	const navigate = useNavigate();
	const [data, setData] = useState([]);
	const [userBackupData, setUserBackupData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [selectedRowKeys, setSelectedRowKeys] = useState([]); // For handling selected rows
	const [tableParams, setTableParams] = useState({
		pagination: {
			current: 1,
			pageSize: 20,
		},
		sortField: null,
		sortOrder: null,
	});
	const [anchorEl, setAnchorEl] = useState(null); // For managing the menu state
	const [selectedRecord, setSelectedRecord] = useState(null); // For keeping track of the selected record

	const handleClick = (event, record) => {
		setAnchorEl(event.currentTarget);
		setSelectedRecord(record);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleViewReceipt = () => {
		if (selectedRecord && selectedRecord.receipt_url) {
			window.open(selectedRecord.receipt_url, "_blank");
		}
		handleClose();
	};

	const columns = [
		{
			title: "S.No.",
			dataIndex: "index",
			sorter: (a, b) => a.index - b.index,
			width: "5%",
		},
		{
			title: "User's Name",
			sorter: (a, b) => {
				const nameA =
					a.user_payment?.user_profile?.name ||
					a.corporate_payment?.name ||
					a.user_archive_payment?.name ||
					a.corporate_archive_payment?.name ||
					"";
				const nameB =
					b.user_payment?.user_profile?.name ||
					b.corporate_payment?.name ||
					b.user_archive_payment?.name ||
					b.corporate_archive_payment?.name ||
					"";
				return nameA.localeCompare(nameB);
			},
			dataIndex: ["user_payment", "user_profile", "name"],
			width: "20%",
			render: (_, record) => {
				// Check for active user payment name
				if (record.user_payment && record.user_payment.user_profile) {
					return record.user_payment.user_profile.name;
				}
				// Check for active corporate payment name
				else if (record.corporate_payment && record.corporate_payment.name) {
					return record.corporate_payment.name;
				}
				// Check for user archive payment name
				else if (
					record.user_archive_payment &&
					record.user_archive_payment.name
				) {
					return <span>{record.user_archive_payment.name} *</span>;
				}
				// Check for corporate archive payment name
				else if (
					record.corporate_archive_payment &&
					record.corporate_archive_payment.name
				) {
					return <span>{record.corporate_archive_payment.name} *</span>;
				}
				// If none of the above, return 'N/A'
				else {
					return "N/A";
				}
			},
		},
		{
			title: "Amount",
			dataIndex: "amount",
			width: "20%",
			render: (text) => `$${text}`,
		},
		{
			title: "Payment Mode",
			dataIndex: "payment_mode",
			width: "20%",
		},
		{
			title: "Transaction Date",
			dataIndex: "created_at",
			width: "20%",
			render: (date) => moment(date).format("MM-DD-YYYY HH:mm A"),
		},
		{
			title: "Action",
			dataIndex: "action",
			render: (_, record) => (
				<Space size="middle">
					<IconButton
						aria-controls="lecture-menu"
						aria-haspopup="true"
						onClick={(event) => handleClick(event, record)}
					>
						<MoreVertIcon />
					</IconButton>
				</Space>
			),
		},
	];

	// Get all users
	const getData = async (params = {}) => {
		try {
			setLoading(true);
			let result = await GetPayment(localStorage.getItem("adminToken"), params);
			// Adding index for serial number
			const newData = result.data.data.map((item, index) => ({
				...item,
				index: index + 1,
			}));
			setData(newData);
			setUserBackupData(newData);
			console.log(newData, "newData");
		} catch (e) {
			console.log(e);
			if (e.response && e.response.status === 401) {
				navigate("/error401");
				console.log("You do not have access to this page as a sub-admin.");
			} else {
				console.log("Error loading data. Please try again later.");
			}
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		getData({
			page: tableParams.pagination.current,
			pageSize: tableParams.pagination.pageSize,
			sortField: tableParams.sortField,
			sortOrder: tableParams.sortOrder,
		});
	}, [tableParams]);

	const handleTableChange = (pagination, filters, sorter) => {
		setTableParams({
			pagination,
			filters,
			sortField: sorter.field,
			sortOrder: sorter.order,
		});
	};

	const onSearch = (searchField) => {
		const LIST = [...userBackupData];
		const searchList = [];

		for (let i in LIST) {
			if (
				LIST[i]?.name?.toLowerCase()?.includes(searchField?.toLowerCase()) ||
				LIST[i]?.email?.toLowerCase()?.includes(searchField?.toLowerCase())
			) {
				searchList.push(LIST[i]);
			}
		}

		setData(searchList);
	};

	const rowSelection = {
		selectedRowKeys,
		onChange: (selectedRowKeys) => {
			setSelectedRowKeys(selectedRowKeys);
		},
	};

	const exportToCSV = () => {
		const csvContent =
			"data:text/csv;charset=utf-8," +
			"ID,Name,Receipt URL,Payment Mode,Amount,Transaction Date\n" +
			data
				.map((row) => {
					const name =
						row.user_payment?.user_profile?.name ||
						row.corporate_payment?.name ||
						"N/A";
					const receiptUrl = row.receipt_url || "N/A";
					const amount = row.amount || "N/A";
					const paymentMode = row.payment_mode || "N/A";
					const transactionDate =
						moment(row.created_at).format("YYYY-MM-DD HH:mm:ss") || "N/A";
					return `${row.id},${name},${receiptUrl},${paymentMode},${amount},${transactionDate}`;
				})
				.join("\n");

		const encodedURI = encodeURI(csvContent);
		const link = document.createElement("a");
		link.setAttribute("href", encodedURI);
		link.setAttribute("download", "payments.csv");
		document.body.appendChild(link);
		link.click();
	};

	return (
		<Box>
			<Box
				display="flex"
				justifyContent="space-between"
				alignItems="center"
				marginBottom="20px"
			>
				<div>
					<h3 className="page-title">PAYMENT MANAGEMENT</h3>
					<p className="page-sub-title">View Payment History</p>
				</div>
				<Box display="flex" justifyContent="space-between" alignItems="center">
					<Box>
						<span className="p-input-icon-left">
							<i className="pi pi-search" />
							<InputText
								type="search"
								onChange={(e) => {
									onSearch(e.target.value);
								}}
								placeholder="Search..."
							/>
						</span>
						<Button
							icon="pi pi-cloud-download"
							severity="success"
							style={{
								marginLeft: "10px",
								borderRadius: "5px",
								height: "47px",
							}}
							onClick={exportToCSV}
						/>
					</Box>
				</Box>
			</Box>
			<Table
				rowSelection={rowSelection} // Add row selection
				columns={columns}
				rowKey={(record) => record.id}
				dataSource={data}
				pagination={tableParams.pagination}
				loading={loading}
				onChange={handleTableChange}
			/>
			<Menu
				id="lecture-menu"
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
			>
				<MenuItem onClick={handleViewReceipt}>View Receipt</MenuItem>
			</Menu>
		</Box>
	);
};

export default Payment;
