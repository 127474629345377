/** @format */

import React, { useLayoutEffect, useState, useRef } from "react";
import { Box, Menu, MenuItem, IconButton, Typography } from "@mui/material";
import { ExclamationCircleFilled } from "@ant-design/icons";
import ListGroup from "react-bootstrap/ListGroup";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import Form from "react-bootstrap/Form";
import { useNavigate, useParams } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { message, Modal } from "antd";
import { Row } from "react-bootstrap";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  CreateLecture,
  DeleteLecture,
  GetCourseById,
  UpdateLecture,
} from "../../services/Api/CourseApi";
import "./Course.css";

const { confirm } = Modal;

const EditLecture = () => {
  const [name, setName] = useState("");
  const [quizName, setQuizName] = useState("");
  const [description, setDescription] = useState("");
  const [disable, setDisable] = useState(false);
  const [lectureType, setLectureType] = useState("");
  const [editLectureType, setEditLectureType] = useState(null);
  const [editLecture, setEditLecture] = useState(null);
  const navigate = useNavigate();
  const { id } = useParams();
  const [courseData, setCourseData] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedLecture, setSelectedLecture] = useState(null);
  const editorRef = useRef();

  const getCourseData = () => {
    GetCourseById(id)
      .then((res) => {
        setCourseData(res.data.data);
        console.log("GetCourseById=>", res.data.data);
      })
      .catch((err) => {
        console.log(err, "error");
      });
  };

  console.log(courseData, "courseData");

  useLayoutEffect(() => {
    getCourseData();
  }, [id, editLecture]);

  const showDeleteConfirm = (lectureId) => {
    confirm({
      title: "Are you sure you want to delete this lecture?",
      icon: <ExclamationCircleFilled />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        handleDelete(lectureId);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const handleDelete = async (lectureId) => {
    try {
      await DeleteLecture(lectureId, localStorage.getItem("adminToken"));
      message.success("Lecture deleted successfully");
      getCourseData();
    } catch (error) {
      message.error("Error deleting lecture");
    }
  };

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setDescription(data);
  };

  const handleEditorChangeEdit = (event, editor) => {
    const data = editor.getData();
    setEditLecture((prevData) => ({
      ...prevData,
      content: data,
    }));
  };

  const customUploadAdapter = (loader) => {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => {
            resolve({ default: reader.result });
          };
          reader.onerror = (error) => {
            reject(error);
          };
          loader.file.then((file) => {
            reader.readAsDataURL(file);
          });
        });
      },
    };
  };

  function CustomUploadAdapterPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return customUploadAdapter(loader);
    };
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setDisable(true);

    if (!lectureType) {
      message.error("Please select a section type");
      setDisable(false);
      return;
    }
    if (!name) {
      message.error("Please enter name");
      setDisable(false);
      return;
    }

    if (!description) {
      message.error("Please enter description");
      setDisable(false);
      return;
    }

    try {
      console.log(id, "courseId");
      const formData = new FormData();
      formData.append("name", name);
      formData.append("content", description);
      formData.append("type", lectureType);
      formData.append("course_id", id);

      const response = await CreateLecture(formData);

      if (response.status === 201) {
        message.success("Lecture added successfully!");
        setName("");
        setDescription("");
        setLectureType("");
        getCourseData(); // Refresh course data
      }

      setDisable(false);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        message.error("Token expired");
        localStorage.removeItem("adminToken");
        setTimeout(() => {
          navigate("/Login");
        }, 1000);
      } else {
        message.error("Something went wrong");
      }
      setDisable(false);
    }
  };

  const onChange = (e) => {
    setEditLecture({ ...editLecture, [e.target.name]: e.target.value });
  };

  const handlQuizeSubmit = async (event) => {
    event.preventDefault();
    setDisable(true);

    if (!lectureType) {
      message.error("Please select a section type");
      setDisable(false);
      return;
    }
    if (!quizName) {
      message.error("Please enter name");
      setDisable(false);
      return;
    }

    try {
      console.log(id, "courseId");
      const formData = new FormData();
      formData.append("name", quizName);
      formData.append("type", lectureType);
      formData.append("course_id", id);

      const response = await CreateLecture(formData);

      if (response.status === 201) {
        message.success("Lecture added successfully!");
        const quizId = response.data.data.id;
        navigate(`/addQuiz/${quizId}`);
        setQuizName("");
        setLectureType("");
        getCourseData(); // Refresh course data
      }

      setDisable(false);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        message.error("Token expired");
        localStorage.removeItem("adminToken");
        setTimeout(() => {
          navigate("/Login");
        }, 1000);
      } else {
        message.error("Something went wrong");
      }
      setDisable(false);
    }
  };

  const handleLectureEditSubmit = (e) => {
    e.preventDefault();
    setDisable(true);

    const formData = new FormData();
    formData.append("name", editLecture.name || "");
    formData.append("content", editLecture.content || "");

    UpdateLecture(editLecture.id, formData)
      .then((res) => {
        if (res.status === 200) {
          message.success("Lecture updated successfully!");
          setLectureType(null);
          setEditLecture(null);
          setEditLectureType(null);
          getCourseData(); // Refresh course data
        }
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          message.error("Token expired!");
          localStorage.removeItem("adminToken");
          setTimeout(() => {
            navigate("/Login");
          }, 3000);
        } else {
          message.error("Something went wrong");
        }
      })
      .finally(() => {
        setDisable(false);
      });
  };

  const handleQuizEditSubmit = (e) => {
    e.preventDefault();
    setDisable(true);

    const formData = new FormData();
    formData.append("name", editLecture.name || "");

    UpdateLecture(editLecture.id, formData)
      .then((res) => {
        if (res.status === 200) {
          message.success("Lecture updated successfully!");
          const quizId = editLecture.id;
          navigate(`/addQuiz/${quizId}`);
          setQuizName("");
          setLectureType(null);
          setEditLecture(null);
          setEditLectureType(null);
          getCourseData(); // Refresh course data
          if (editorRef.current) {
            editorRef.current.setData(""); // Clear the CKEditor content
          }
        }
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          message.error("Token expired!");
          localStorage.removeItem("adminToken");
          setTimeout(() => {
            navigate("/Login");
          }, 3000);
        } else {
          message.error("Something went wrong");
        }
      })
      .finally(() => {
        setDisable(false);
      });
  };

  const handleClear = () => {
    setName("");
    setDescription("");
    setLectureType(null);
    setEditLecture(null);
    setEditLectureType(null);
    if (editorRef.current) {
      editorRef.current.setData(""); // Clear the CKEditor content
    }
  };

  const handleMenuClick = (event, lecture) => {
    setAnchorEl(event.currentTarget);
    setSelectedLecture(lecture);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedLecture(null);
  };

  const handleEditLecture = (lecture) => {
    if (lecture) {
      setEditLectureType(lecture.type === "Text" ? "EditText" : "EditQuiz");
      setEditLecture(lecture);
      if (lecture.type === "Text" && editorRef.current) {
        editorRef.current.setData(lecture.content); // Set CKEditor content
      }
    }
    handleMenuClose();
  };

  const handleDeleteLecture = (id) => {
    showDeleteConfirm(id); // Use showDeleteConfirm to handle delete
    handleMenuClose();
  };

  return (
    <Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom="20px"
      >
        <div>
          <h3 className="page-title">COURSE MANAGEMENT</h3>
          <p className="page-sub-title">Create New Section</p>
        </div>
        <div>
          <Button
            icon="pi pi-arrow-left"
            severity="secondary"
            onClick={() => navigate(`/editCourse/${id}`)}
            style={{ borderRadius: "5px", height: "47px" }}
          >
            <span style={{ marginLeft: "5px" }}>Return to Course</span>
          </Button>
        </div>
      </Box>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <Card style={{ width: "30%", marginRight: "30px" }}>
          {courseData && (
            <>
              <Typography variant="h5">{courseData.name}</Typography>

              <ListGroup>
                <p style={{ marginTop: "10px" }}>Sections:</p>
                {courseData.course_lectures &&
                courseData.course_lectures.length > 0 ? (
                  courseData.course_lectures.map((lecture, index) => (
                    <ListGroup.Item key={index}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <div style={{ alignContent: "center" }}>
                          {lecture.name}
                        </div>
                        <div>
                          <IconButton
                            aria-controls="simple-menu"
                            aria-haspopup="true"
                            onClick={(event) => handleMenuClick(event, lecture)}
                          >
                            <MoreVertIcon />
                          </IconButton>
                          <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                          >
                            <MenuItem
                              onClick={() => {
                                handleEditLecture(selectedLecture);
                                handleMenuClose();
                              }}
                            >
                              Edit Section
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                handleDeleteLecture(selectedLecture.id);
                                handleMenuClose();
                              }}
                            >
                              Delete Section
                            </MenuItem>
                          </Menu>
                        </div>
                      </div>
                    </ListGroup.Item>
                  ))
                ) : (
                  <div className="no-lecture-container">
                    <p style={{ fontWeight: "bold" }}>NO SECTION ADDED YET😔</p>
                  </div>
                )}
              </ListGroup>
            </>
          )}
        </Card>
        <Card style={{ width: "70%" }}>
          {editLectureType !== null && editLecture ? (
            <>
              {editLectureType === "EditText" ? (
                <>
                  <Row>
                    <Form.Group className="mb-3">
                      <Form.Label>Section Name:</Form.Label>
                      <Form.Control
                        name="name"
                        type="text"
                        value={editLecture?.name || ""}
                        onChange={onChange}
                      />
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group className="mb-3">
                      <Form.Label>Section Content:</Form.Label>
                      <CKEditor
                        data={editLecture?.content || ""}
                        editor={ClassicEditor}
                        onChange={handleEditorChangeEdit}
                        config={{
                          height: "1000px",
                          extraPlugins: [CustomUploadAdapterPlugin],
                          toolbar: [
                            "heading",
                            "|",
                            "bold",
                            "italic",
                            "link",
                            "bulletedList",
                            "numberedList",
                            "blockQuote",
                            "imageUpload",
                            "undo",
                            "redo",
                          ],
                        }}
                        onReady={(editor) => {
                          editorRef.current = editor;
                          editor.setData(editLecture?.content || ""); // Ensure CKEditor content is set correctly
                        }}
                      />
                    </Form.Group>
                  </Row>
                  <div>
                    <Button
                      severity="info"
                      onClick={handleLectureEditSubmit}
                      disabled={disable}
                      style={{
                        height: "45px",
                        padding: "20px",
                        borderRadius: "5px",
                        margin: "10px",
                      }}
                    >
                      {disable ? "Submitting..." : "Save Changes"}
                    </Button>
                    <Button
                      severity="secondary"
                      onClick={handleClear}
                      style={{
                        height: "45px",
                        padding: "20px",
                        borderRadius: "5px",
                        margin: "10px",
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </>
              ) : (
                <>
                  <Row>
                    <Form.Group className="mb-3">
                      <Form.Label>Quiz Name:</Form.Label>
                      <Form.Control
                        type="text"
                        value={editLecture?.name || ""}
                        onChange={onChange}
                      />
                    </Form.Group>
                  </Row>
                  <div>
                    <Button
                      severity="info"
                      onClick={handleQuizEditSubmit}
                      disabled={disable}
                      style={{
                        height: "45px",
                        padding: "20px",
                        borderRadius: "5px",
                        margin: "10px",
                      }}
                    >
                      {disable ? "Submitting..." : "Save Changes"}
                    </Button>
                    <Button
                      severity="secondary"
                      onClick={handleClear}
                      style={{
                        height: "45px",
                        padding: "20px",
                        borderRadius: "5px",
                        margin: "10px",
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </>
              )}
            </>
          ) : (
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3">
                <Form.Label style={{ fontWeight: "bold" }}>
                  Select Section Type:
                </Form.Label>
                <div>
                  <Form.Check
                    inline
                    label="Lecture"
                    type="radio"
                    id="Text"
                    name="lectureType"
                    value="Text"
                    checked={lectureType === "Text"}
                    onChange={(e) => setLectureType(e.target.value)}
                  />
                  <Form.Check
                    inline
                    label="Quiz"
                    type="radio"
                    id="Quiz"
                    name="lectureType"
                    value="Quiz"
                    checked={lectureType === "Quiz"}
                    onChange={(e) => setLectureType(e.target.value)}
                  />
                </div>
              </Form.Group>

              {lectureType === "Text" && (
                <>
                  <Row>
                    <Form.Group className="mb-3">
                      <Form.Label>Section Name:</Form.Label>
                      <Form.Control
                        type="text"
                        required
                        placeholder="Enter Section Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="new_form_control"
                      />
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group className="mb-3">
                      <Form.Label>Section Content:</Form.Label>
                      <CKEditor
                        editor={ClassicEditor}
                        onChange={handleEditorChange}
                        config={{
                          height: "1000px",
                          ckfinder: {
                            uploadUrl: "http://localhost:5000/images/upload",
                          },
                          toolbar: [
                            "heading",
                            "|",
                            "bold",
                            "italic",
                            "link",
                            "bulletedList",
                            "numberedList",
                            "blockQuote",
                            "imageUpload",
                            "undo",
                            "redo",
                          ],
                        }}
                        onReady={(editor) => {
                          editorRef.current = editor; // Assign the editor instance to ref
                        }}
                      />
                    </Form.Group>
                  </Row>
                  <div>
                    <Button
                      severity="info"
                      onClick={handleSubmit}
                      disabled={disable}
                      style={{
                        height: "45px",
                        padding: "20px",
                        borderRadius: "5px",
                        margin: "10px",
                      }}
                    >
                      {disable ? "Submitting..." : "Submit"}
                    </Button>
                    <Button
                      severity="secondary"
                      onClick={handleClear}
                      style={{
                        height: "45px",
                        padding: "20px",
                        borderRadius: "5px",
                        margin: "10px",
                      }}
                    >
                      Clear
                    </Button>
                  </div>
                </>
              )}

              {lectureType === "Quiz" && (
                <>
                  <Row>
                    <Form.Group className="mb-3">
                      <Form.Label>Quiz Name:</Form.Label>
                      <Form.Control
                        type="text"
                        required
                        placeholder="Enter Quiz Name"
                        value={quizName}
                        onChange={(e) => setQuizName(e.target.value)}
                        className="new_form_control"
                      />
                    </Form.Group>
                  </Row>

                  <div>
                    <Button
                      severity="info"
                      onClick={handlQuizeSubmit}
                      disabled={disable}
                      style={{
                        height: "45px",
                        padding: "20px",
                        borderRadius: "5px",
                        margin: "10px",
                      }}
                    >
                      {disable ? "Submitting..." : "Save & Add Questions"}
                    </Button>
                    <Button
                      severity="secondary"
                      onClick={handleClear}
                      style={{
                        height: "45px",
                        padding: "20px",
                        borderRadius: "5px",
                        margin: "10px",
                      }}
                    >
                      Clear
                    </Button>
                  </div>
                </>
              )}
            </Form>
          )}
        </Card>
      </div>
      <div style={{ display: "flex", flexDirection: "row-reverse" }}>
				<Button
					icon="pi pi-check"
					onClick={() => navigate(`/course`)}
					// lable="Complete"
					style={{
						height: "45px",
						marginLeft: "10px",
						padding: "20px",
						borderRadius: "5px",
						marginTop: "20px",
						backgroundColor: "green",
						border: "none",
					}}
				></Button>
			</div>

  
    </Box>
  );
};

export default EditLecture;
