import { Box } from "@mui/material";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import React, { useLayoutEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { FaCheckCircle } from "react-icons/fa";
import {
	CreateQuestion,
	CreateOptions,
	CreateCorrectOption,
	GetQuestionsByLectureId,
} from "../../services/Api/CourseApi";
import { message } from "antd";
import { Form } from "react-bootstrap";
import Select from "react-select";
import "./Course.css";

const AddQuizQuestions = () => {
	const { id } = useParams();
	const navigate = useNavigate();
	const [courseData, setCourseData] = useState(null);
	const [disable, setDisable] = useState(false);
	const [showOptions, setShowOptions] = useState(false);
	const [questionId, setQuestionId] = useState(null);
	const [question, setQuestion] = useState("");
	const [options, setOptions] = useState([""]);
	const [correctAnswer, setCorrectAnswer] = useState(""); // Will hold option ID
	const [optionDropdown, setOptionDropdown] = useState([]);

	const getCourseData = () => {
		GetQuestionsByLectureId(id)
			.then((res) => {
				setCourseData(res.data.data);
				console.log("GetCourseById=>", res.data.data);
			})
			.catch((err) => {
				console.log(err, "error");
			});
	};

	useLayoutEffect(() => {
		getCourseData();
	}, [id]);

	const handleSubmit = async (event) => {
		event.preventDefault();
		setDisable(true);

		if (!question) {
			message.error("Please type Question");
			setDisable(false);
			return;
		}

		try {
			const formData = new FormData();
			formData.append("question", question);
			formData.append("lecture_id", id);
			formData.append("course_id", courseData.course_id);

			const response = await CreateQuestion(formData);

			if (response.status === 201) {
				const questionId = response.data.data.id;
				setQuestionId(questionId);
				message.success("Question added successfully!");
				setShowOptions(true);
				setOptions([""]); // Reset options for new question
			}

			setDisable(false);
		} catch (error) {
			if (error.response && error.response.status === 401) {
				message.error("Token expired");
				localStorage.removeItem("adminToken");
				setTimeout(() => {
					navigate("/Login");
				}, 1000);
			} else {
				message.error("Something went wrong");
			}
			setDisable(false);
		}
	};

	const handleOptionChange = (index, value) => {
		const newOptions = [...options];
		newOptions[index] = value;
		setOptions(newOptions);
	};

	const handleAddOptionClick = () => {
		setOptions((prevOptions) => [...prevOptions, ""]); // Add a new empty option input
	};

	const handleAddAllOptions = async () => {
		if (options.some((option) => !option.trim())) {
			message.error("Options cannot be empty");
			return;
		}
	
		setDisable(true);
	
		try {
			const formData = new FormData();
			formData.append("question_id", questionId);
	
			// Append each option with the key 'options[]'
			options.forEach((option) => {
				formData.append("options[]", option);
			});
	
			const response = await CreateOptions(formData);
	
			if (response.status === 201) {
				message.success("All options added successfully!");

				// Prepare options for dropdown with IDs
				const dropdownOptions = response.data.data.map((option) => ({
					label: option.option, // Display text
					value: option.id // ID of the option
				}));
				setOptionDropdown(dropdownOptions);

				setShowOptions(false); // Hide options after submission
			}
		} catch (error) {
			message.error("Failed to add all options");
		} finally {
			setDisable(false);
		}
	};

	const handleCorrectAnswer = async (event) => {
		event.preventDefault();
		setDisable(true);
		if (!correctAnswer) {
			message.error("Please provide correct answer");
			setDisable(false);
			return;
		}

		try {
			const formData = new FormData();
			formData.append("question_id", questionId);
			formData.append("right_answer_id", correctAnswer); // Pass the correct option ID

			const response = await CreateCorrectOption(formData);

			if (response.status === 201) {
				message.success("Correct answer added successfully!");
				setQuestion("");
				setOptions([""]);
				setCorrectAnswer(""); // Reset correct answer
				setQuestionId(null); // Reset question ID
				setOptionDropdown([]); // Clear dropdown options
				document.getElementById("question-input").focus();
			}
		} catch (error) {
			message.error("Failed to add correct answer");
			setDisable(false);
		}
	};

	return (
		<Box>
			<Box
				display="flex"
				justifyContent="space-between"
				alignItems="center"
				marginBottom="20px"
			>
				<div>
					<h3 className="page-title">{courseData?.name}</h3>
				</div>
				<div>
					<Button
						label="View All Questions"
						iconPos="right"
						onClick={() => navigate(`/viewQuestions/${id}`)}
						style={{ borderRadius: "5px", height: "47px", marginRight: "20px" }}
					></Button>
					<Button
						severity="secondary"
						onClick={() => navigate(`/addLecture/${courseData.course_id}`)}
						style={{ borderRadius: "5px", height: "47px" }}
					>
						<span style={{ marginLeft: "5px" }}>Return to Course</span>
					</Button>
				</div>
			</Box>
			<Card>
				<Form onSubmit={handleSubmit}>
					<Form.Group className="mb-3 w-100">
						<Form.Label>Question:</Form.Label>
						<Form.Control
							type="text"
							required
							placeholder="Please enter Question here"
							value={question}
							onChange={(e) => setQuestion(e.target.value)}
							className="new_form_control"
							id="question-input"
						/>
					</Form.Group>

					<FaCheckCircle
						className="view_all_questions_button"
						onClick={handleSubmit}
						style={{
							marginTop: "-90px",
							marginLeft: "1042px",
							fontSize: "20px",
						}}
					/>

					{showOptions && (
						<>
							{options.map((option, index) => (
								<Form.Group key={index} className="mb-3 w-50">
									<Form.Label>Option {index + 1}:</Form.Label>
									<Form.Control
										type="text"
										placeholder={`Enter option ${index + 1}`}
										value={option}
										onChange={(e) => handleOptionChange(index, e.target.value)}
										className="new_form_control"
									/>
								</Form.Group>
							))}

							<h6
								style={{
									marginLeft: "406px",
									marginTop: "10px",
									fontSize: "smaller",
								}}
								onClick={handleAddOptionClick}
							>
								+ Add More Options
							</h6>

							{options.length >= 2 && (
								<Button
									onClick={handleAddAllOptions}
									disabled={disable}
									style={{
										borderRadius: "5px",
										height: "47px",
										marginRight: "20px",
									}}
									label="Submit Options"
									severity="success"
								></Button>
							)}
						</>
					)}

					{/* Dropdown for selecting correct answer */}
					{optionDropdown.length > 0 && (
						<Form.Group className="mb-3 w-50">
							<Form.Label>Choose Correct Option:</Form.Label>
							<Select
								options={optionDropdown}
								onChange={(option) => setCorrectAnswer(option?.value)}
								placeholder="Select correct option"
							/>
							<Button
								onClick={handleCorrectAnswer}
								disabled={disable}
								style={{
									borderRadius: "5px",
									height: "47px",
									marginTop: "10px",
								}}
								label="Submit Correct Answer"
								severity="success"
							></Button>
						</Form.Group>
					)}
				</Form>
			</Card>
		</Box>
	);
};

export default AddQuizQuestions;
