/** @format */

import { Box } from "@mui/material";
import React from "react";
import { useState } from "react";
import { Button } from "primereact/button";
import Form from "react-bootstrap/Form";
import { CreateAdmin } from "../../services/Api/Api";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { Card } from "primereact/card";
import { message } from "antd";
import { TiInfoOutline } from "react-icons/ti";

import { Checkbox, Col, Row } from "antd";

const AddAdmin = () => {
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [roleId, setRoleId] = useState("");
	const [disable, setDisable] = useState(false);

	const navigate = useNavigate();

	const handleSubmit = async (event) => {
		event.preventDefault();
		setDisable(true);

		if (!name) {
			setDisable(false);
			message.error("Please enter name");

			return;
		}
		if (!email) {
			setDisable(false);
			message.error("Please enter email");
			return;
		}
		if (!roleId) {
			setDisable(false);
			message.error("Please choose any Role");
			return;
		}
		try {
			const formData = new FormData();
			formData.append("name", name);
			formData.append("email", email);
			formData.append("role_id", parseInt(roleId, 10));

			const response = await CreateAdmin(formData);

			if (response.status === 201) {
				message.success("Admin added successfully");
			}
			setTimeout(() => {
				navigate("/adminList");
			}, 1000);
			setDisable(false);
		} catch (error) {
			if (error.response && error.response.status === 400) {
				message.error("Email already exists");
			} else if (error.response.status === 401) {
				message.error("Token expired");
				localStorage.removeItem("adminToken");
				setTimeout(() => {
					navigate("/Login");
				}, 1000);
			} else {
				message.error("Something went wrong");
			}
			setDisable(false);
		}
	};

	const navigateToAdmin = () => {
		navigate("/adminList");
	};

	const [selectedValues, setSelectedValues] = useState([]);

	const onChange = (checkedValues) => {
		setSelectedValues(checkedValues);
	};

	return (
		<Box>
			<Box
				display="flex"
				justifyContent="space-between"
				alignItems="center"
				marginBottom="20px"
			>
				<div>
					<h3 className="page-title">ADMIN MANAGEMENT</h3>
					<p className="page-sub-title">Create New Admin</p>
				</div>
				<div>
					<Button
						icon="pi pi-arrow-left"
						severity="secondary"
						onClick={navigateToAdmin}
						style={{ borderRadius: "5px", height: "47px" }}
					>
						<span style={{ marginLeft: "5px" }}>Return to Admin</span>
					</Button>
				</div>
			</Box>
			<div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
				<Card style={{ width: "70%" }}>
					<div>
						<Form>
							<Form.Group className="mb-3">
								<Form.Label> Name</Form.Label>
								<Form.Control
									type="text"
									required
									placeholder="Enter name"
									value={name}
									onChange={(e) => setName(e.target.value)}
									className="new_form_control"
								/>
							</Form.Group>

							<Form.Group className="mb-3">
								<Form.Label>Email</Form.Label>
								<Form.Control
									type="email"
									placeholder="Enter email"
									value={email}
									required
									onChange={(e) => setEmail(e.target.value)}
									className="new_form_control"
								/>
							</Form.Group>

							<Form.Group className="mb-3">
								<Form.Label>Select Pages to Manage:</Form.Label>
								<Checkbox.Group style={{ width: "100%" }} onChange={onChange}>
									<Row>
										{/* Primary Checkboxes */}
										<Col span={8}>
											<Checkbox value="OWNER">Owner</Checkbox>
										</Col>
										<Col span={8}>
											<Checkbox value="ADMIN">Admin</Checkbox>
										</Col>
										<Col span={8}>
											<Checkbox value="ADMIN_MANAGEMENT">
												Admin Management
											</Checkbox>
										</Col>
										<Col span={8}>
											<Checkbox value="USER_MANAGEMENT">
												User Management
											</Checkbox>
										</Col>
										<Col span={8}>
											<Checkbox value="COURSE_MANAGEMENT">
												Course Management
											</Checkbox>
										</Col>
										<Col span={8}>
											<Checkbox value="CONTENT_MANAGEMENT">
												Content Management
											</Checkbox>
										</Col>
										<Col span={8}>
											<Checkbox value="PAYMENT_MANAGEMENT">
												Payment Management
											</Checkbox>
										</Col>
										<Col span={8}>
											<Checkbox value="DASHBOARD_ACCESS">
												Dashboard Access
											</Checkbox>
										</Col>

										{/* Child Checkboxes for User Management */}
										{selectedValues.includes("USER_MANAGEMENT") && (
											<>
												<Col span={8} offset={2}>
													<Checkbox value="INDIVIDUAL_USER_MANAGEMENT">
														Individual User Management
													</Checkbox>
												</Col>
												<Col span={8} offset={2}>
													<Checkbox value="CORPORATE_USER_MANAGEMENT">
														Corporate User Management
													</Checkbox>
												</Col>
											</>
										)}

										{/* Child Checkboxes for Course Management */}
										{selectedValues.includes("COURSE_MANAGEMENT") && (
											<>
												<Col span={8} offset={2}>
													<Checkbox value="COURSE">Course</Checkbox>
												</Col>
												<Col span={8} offset={2}>
													<Checkbox value="TEST">Test</Checkbox>
												</Col>
											</>
										)}

										{/* Child Checkboxes for Content Management */}
										{selectedValues.includes("CONTENT_MANAGEMENT") && (
											<>
												<Col span={8} offset={2}>
													<Checkbox value="FORUM_MANAGEMENT">
														Forum Management
													</Checkbox>
												</Col>
												<Col span={8} offset={2}>
													<Checkbox value="CONTENT_US_FORM">
														Content Us Form
													</Checkbox>
												</Col>
											</>
										)}
									</Row>
								</Checkbox.Group>
							</Form.Group>

							<div style={{ marginTop: "50px" }}>
								<Button
									icon="pi pi-check"
									severity="info"
									htmlType="submit"
									type="primary"
									onClick={handleSubmit}
									style={{
										borderRadius: "5px",
										margin: "0px 0px",
										height: "40px",
									}}
								>
									Save
								</Button>

								<Button
									icon="pi pi-times"
									severity="secondary"
									onClick={(e) => {
										navigateToAdmin();
									}}
									style={{
										borderRadius: "5px",
										marginLeft: "10px",
										height: "40px",
									}}
								>
									Cancel
								</Button>
							</div>
						</Form>
					</div>
				</Card>
				<Card
					className="admin_description"
					style={{ width: "30%", marginLeft: "10px" }}
				>
					<div
						style={{
							display: "flex",
							alignItems: "center",
							marginBottom: "15px",
						}}
					>
						<TiInfoOutline
							style={{
								fontSize: "24px",
								color: "red",
								marginRight: "10px",
							}}
						/>
						<h4 style={{ margin: 0 }}>Important Note:</h4>
					</div>
					<p style={{ fontSize: "14px", color: "#555" }}>
						You can assign access to specific pages for this new admin. The
						admin will only be able to access the pages you assign, and all
						other pages will remain inaccessible.
					</p>
					<div style={{ paddingLeft: "30px", fontSize: "14px", color: "#555" }}>
						<ul style={{ listStyleType: "disc" }}>
							<li>
								Assign page access based on the admin's role and
								responsibilities.
							</li>
							<li>
								Admins with restricted access can only manage specific sections.
							</li>
							<li>
								For example, an admin can be given access to User but not to
								Course or Admin.
							</li>
						</ul>
					</div>
				</Card>
			</div>
		</Box>
	);
};

export default AddAdmin;
