/** @format */

import { Box } from "@mui/material";
import React, { useLayoutEffect } from "react";
import { Form } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import { GetForumById, UpdateForum } from "../../../services/Api/ContentApi";
import { message } from "antd";
import { Button } from "primereact/button";
import { Card } from "primereact/card";

const EditForum = () => {
	const { id } = useParams();
	const [idData, setIdData] = React.useState("");

	//get role By ID
	useLayoutEffect(() => {
		GetForumById(id)
			.then((res) => {
				setIdData(res.data.data);
			})
			.catch((err) => {
				console.log(err, "error");
			});
	}, [id]);

	//update role api implementation
	const handleNameChange = (e) => {
		console.log("Selected value:", e.target.value);
		setIdData({ ...idData, [e.target?.name]: e.target?.value });
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		const formData = new FormData();
		formData.append("answer", idData?.answer ? idData?.answer : "");

		UpdateForum(id, formData)
			.then((res) => {
				if (res.status === 200) {
					message.success("Data updated successfully!");
				}
				navigate("/forum");
			})
			.catch((err) => {
				if (err.response && err.response.status === 401) {
					message.error("Token expired!");
					localStorage.removeItem("adminToken");
					setTimeout(() => {
						navigate("/Login");
					}, 3000);
				} else {
					message.error("Something went wrong");
				}
			});
	};

	const navigate = useNavigate();
	const navigateToRole = () => {
		navigate("/forum");
	};
	return (
		<Box m="20px">
			<Box
				display="flex"
				justifyContent="space-between"
				alignItems="center"
				marginBottom="20px"
			>
				<div>
					<h3 className="page-title">FORUM MANAGEMENT</h3>
					<p className="page-sub-title">
						Manage User Queries By Answering Them
					</p>
				</div>
				<div>
					<Button
						icon="pi pi-arrow-left"
						severity="secondary"
						onClick={navigateToRole}
						style={{ borderRadius: "5px", height: "47px" }}
					>
						<span style={{ marginLeft: "5px" }}>Return to Forum</span>
					</Button>
				</div>
			</Box>
			<Card>
				<div>
					<Form>
						<Form.Group className="mb-3">
							<Form.Label>Question</Form.Label>
							<Form.Control
								type="text"
								defaultValue={idData?.question}
								name="question"
								disabled
							/>
						</Form.Group>
						<Form.Group className="mb-3">
							<Form.Label>Answer</Form.Label>
							<Form.Control
								as="textarea"
                                rows={9}
								defaultValue={idData?.answer}
                                placeholder="Please Provide Answer..."
								name="answer"
								onChange={(e) => handleNameChange(e)}
							/>
						</Form.Group>
					</Form>
					<div style={{ marginTop: "40px" }}>
						<Button
							icon="pi pi-check"
							severity="info"
							type="submit"
							onClick={handleSubmit}
							style={{
								height: "45px",
								padding: "20px",
								borderRadius: "5px",
							}}
						>
							Save
						</Button>

						<Button
							icon="pi pi-times"
							severity="secondary"
							onClick={(e) => {
								navigateToRole();
							}}
							style={{
								marginLeft: "10px",
								marginTop: "10px",
								height: "45px",
								padding: "20px",
								borderRadius: "5px",
							}}
						>
							Cancel
						</Button>
					</div>
				</div>
			</Card>
		</Box>
	);
};

export default EditForum;
