/** @format */

import { Box } from "@mui/material";
import { Button } from "primereact/button";
import React, { useLayoutEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { GetQuestionsByLectureId } from "../../services/Api/CourseApi";
import { Row, Col } from "antd";
import { Form } from "react-bootstrap";
import "./Course.css";

const ViewQuestions = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [courseData, setCourseData] = useState(null);
  const [questions, setQuestions] = useState([]);

  const getCourseData = () => {
    GetQuestionsByLectureId(id)
      .then((res) => {
        setCourseData(res.data.data);
        setQuestions(res.data.data.lecture_quiz);
        console.log("GetCourseById=>", res.data.data);
      })
      .catch((err) => {
        console.log(err, "error");
      });
  };

  useLayoutEffect(() => {
    getCourseData();
  }, [id]);

  return (
    <Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom="20px"
      >
        <div>
          <h3 className="page-title">{courseData?.name}</h3>
        </div>
        <div>
          <Button
            icon="pi pi-arrow-left"
            severity="secondary"
            onClick={() => navigate(`/addLecture/${courseData.course_id}`)}
            style={{ borderRadius: "5px", height: "47px" }}
          >
            <span style={{ marginLeft: "5px" }}>Return to Course</span>
          </Button>
        </div>
      </Box>

      <div className="test-knowledge">
        <Form className="test-form">
          {questions.map((q) => (
            <Form.Group as={Row} className="mb-3" key={q.id}>
              <Form.Label as="legend" column sm={12}>
                {q.question}
              </Form.Label>
              <Col sm={24}>
                {q.quiz_answers.length > 0 ? (
                  <ul>
                    {q.quiz_answers.map((answer) => (
                      <li key={answer.id}>{answer.option}</li>
                    ))}
                  </ul>
                ) : (
                  <p style={{ color: "red" }}>No options added yet</p>
                )}
              </Col>
            </Form.Group>
          ))}
        </Form>
      </div>
    </Box>
  );
};

export default ViewQuestions;
